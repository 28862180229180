






































import { Component, Vue } from 'vue-property-decorator'
import MonitorDayList from '@/components/monitor/MonitorDayList.vue'
import CompanySelect from '@/components/manage/CompanySelect.vue'
import GroupSelect from '@/components/manage/GroupSelect.vue'
import firebase from '@/plugins/firebase'
import { Company } from '@/models/company'
import CompanyNotFound from '@/components/common/CompanyNotFound.vue'
import moment from '@/plugins/moment'
import DatePicker from '@/components/common/DatePicker.vue'
import AuthStore from '@/store/AuthStore'

@Component<MonitorDayIndex>({
  components: { MonitorDayList, CompanySelect, GroupSelect, CompanyNotFound, DatePicker }
})
export default class MonitorDayIndex extends Vue {
  company: firebase.firestore.DocumentSnapshot<Company> | null = null
  dates = [
    moment().format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD')
  ]

  get existsCompany (): boolean {
    return !!AuthStore.companies.length
  }

  get existsGroup (): boolean {
    return !!AuthStore.groups.length
  }

  get isCompanyAdmin (): boolean {
    return AuthStore.isCompanyAdmin
  }

  get xs (): boolean { return this.$vuetify.breakpoint.xs }

  changeDates (dates: string[]): void {
    this.dates = dates
  }
}
