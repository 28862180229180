


































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import dayCollection, { Day } from '@/models/day'
import DayItem from '@/components/common/DayItem.vue'
import { Company } from '@/models/company'
import { Device, converter as deviceConverter } from '@/models/device'
import AuthStore from '@/store/AuthStore'
import moment from '@/plugins/moment'

@Component<MonitorDayList>({
  components: {
    DayItem
  },
  mounted () {
    this.clear()
    this.fetch()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class MonitorDayList extends Vue {
  @Prop({ type: Array, required: true }) dates!: string[]
  unsubscribe: firebase.Unsubscribe | null = null
  docs: firebase.firestore.QueryDocumentSnapshot<Day>[] = []
  limit = 4
  empty = false
  loading = false
  deviceDocs: firebase.firestore.DocumentSnapshot<Device>[] = []

  @Watch('selectedCompany')
  onChangeCompany (): void {
    this.clear()
    this.fetch()
  }

  @Watch('selectedGroup')
  onChangeGroup (): void {
    this.clear()
    this.fetch()
  }

  get selectedCompany (): unknown | null{
    return AuthStore.selectedCompany
  }

  get selectedGroup (): unknown | null{
    return AuthStore.selectedGroup
  }

  get last (): firebase.firestore.DocumentSnapshot<Day> | null {
    if (!this.docs.length) return null
    return this.docs[this.docs.length - 1]
  }

  get dayRef (): firebase.firestore.Query<Day> {
    return AuthStore.dayRef
  }

  clear (): void {
    this.docs = []
  }

  async fetch (): Promise<void> {
    try {
      this.loading = true
      // const ref = this.company ? dayCollection.where('company', '==', this.company.ref) : dayCollection
      let query = this.dayRef
        .where('firstSecond.time', '>=', moment(this.dates[0]).utcOffset(9).toISOString())
        .where('firstSecond.time', '<', moment(this.dates[1]).utcOffset(9).add(1, 'days').toISOString())
        .orderBy('firstSecond.time', 'asc')
      query = this.last ? query.startAfter(this.last) : query
      const sn = await query.limit(this.limit).get()
      this.empty = sn.empty
      if (sn.empty) return

      await this.getDevices(sn.docs)
      this.docs = this.docs.concat(sn.docs)
    } finally {
      this.loading = false
    }
  }

  async getDevices (docs: firebase.firestore.QueryDocumentSnapshot<Day>[]): Promise<void> {
    for (const doc of docs) {
      const day = doc.data()
      if (!day) continue
      if (this.deviceDocs.some(item => item.id === day.device.id)) continue
      const d = await day.device.withConverter(deviceConverter).get()
      if (!d.exists) continue
      this.deviceDocs.push(d)
    }
  }

  onIntersect (entries: unknown, observer: unknown, isIntersecting: boolean): void {
    if (!this.docs.length) return
    if (isIntersecting) this.fetch()
  }
}
